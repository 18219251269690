<template>
	<div class="MyNFTList">
		<div class="MyNFTList-nav">
			<div class="MyNFTList-nav__item" :class="{'active': active === index}" v-for="(item, index) in navList" :key="index" @click="_navItemTap(item, index)">{{ item.title }}  ({{ item.value }})</div>
		</div>
		<div class="MyNFTList-box">
			<div class="d-flex justify-center pt10" v-if="card_list_obj[navList[active].type].length === 0">
				<div class="MyNFTList-nodata">{{ $t('You currently have no cards in this category. You can') }}
					<router-link to="/app/Game/BuyBox">{{ $t('buy boxes') }}</router-link>
					{{ $t('and get your NTF cards.') }}
				</div>
			</div>
			<div class="MyNFTList-card__box" v-show="card_arr.length > 0">
				<div class="MyNFTList-card__item" v-for="(item, index) in card_list_obj[navList[active].type]" :key="index">
					<img class="card-img" src="@/assets/bg_game_NFTcards.svg" alt="" v-if="item.num > 0">
					<img class="card-img" src="@/assets/close_bg.png" alt="" v-else>
					<img class="content-img" :src="getCardImgUrl(item.key)" alt="" v-if="item.num > 0">
					<img class="content-img" :src="getCardImgUrl(item.key, 'close')" alt="" v-else>
					<div class="MyNFTList-card__content">
						<div class="d-flex align-center justify-space-between">
							<div class="flex-1" :class="{'pr6': item.combined_list.length > 0}">
								<div class="d-flex justify-space-between" v-if="item.type !== 9">
									<span class="MyNFTList-card__number">{{ $t('Mining Power') }}</span>
									<span class="MyNFTList-card__number">{{ item.mining_power }}</span>
								</div>
								<div class="d-flex justify-space-between mb-1" v-if="item.type === 9">
									<span class="MyNFTList-card__number">{{ $t('God Strength') }}</span>
									<span class="MyNFTList-card__number">{{ item.god_strength }}</span>
								</div>
								<div class="d-flex justify-space-between" v-if="item.type === 9">
									<span class="MyNFTList-card__number">{{ $t('Bonus Proportion') }}</span>
									<span class="MyNFTList-card__number">{{ item.bonus_proportion }}%</span>
								</div>
							</div>
							<img class="combination-img" src="@/assets/combination_tag.png" alt="" v-show="item.combined_list.length > 0">
						</div>
						<div class="MyNFTList-card__name">
							<div>{{ item.zhHans }}</div>
							<div>{{ item.en }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCardImgUrl } from '@/utils/tools'
import { mapState } from 'vuex'
import { myTotalCards } from '@/utils/cardFactory'
import eventBus from '@/utils/eventBus'
import card_list from '@/data/card'

export default {
	name: 'Display',
	data () {
		return {
			card_list: card_list,
			card_arr: [],
			navList: [],
			active: 0,
			origin_list: [],
			card_list_obj: {},
		}
	},
	created () {
		this.navList = JSON.parse(JSON.stringify(this.card_nav_list))
		if (!this.ADDRESS) {
			for (const key in this.card_list) {
				this.card_arr.push({ ...this.card_list[key], key, num: 0, available: [] })
			}
		}
		this.navList.map(item => {
			this.card_list_obj[item.type] = []
		})
	},
	computed: {
		...mapState(['ADDRESS', 'card_nav_list']),
	},
	mounted () {
		if (this.ADDRESS) {
			this.myCards()
		}
		// 添加 eventBus 监听器
		eventBus.$on('connect-event', (res) => {
			console.log('connect-event', res)
			this.myCards()
		})
	},
	methods: {
		_navItemTap (item, index) {
			this.active = index
		},
		_goDetails (query) {
			if (query.num === 0) return false
			this.$router.push({
				path: '/app/Game/MyNFT/NftDetails',
				query,
			})
		},
		async myCards () {
			const res = await myTotalCards(this.ADDRESS, 10000)
			let key = ''
			res.map(item => {
				this.navList[0].value += item.num
				key = item.key.split('-')[0]
				this.navList.map((nav, i) => {
					if (nav.type === Number(key)) {
						this.navList[i].value += item.num
					}
				})
			})
			for (const key in this.card_list) {
				this.card_arr.push({ ...this.card_list[key], key, num: 0, available: [] })
			}
			this.card_arr.map(item => {
				res.map(item1 => {
					if (item.key === item1.key) {
						item.num = item1.num
						item.available = item1.available
					}
				})
			})
			this.origin_list = this.card_arr
			this.card_arr.map(item => {
				this.card_list_obj[item.type].push(item)
			})
			this.card_list_obj[0] = this.card_arr
			console.log(this.card_list_obj)
		},
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
